import { Popover } from '@headlessui/react';
import Link from 'next/link';

export const NavLink = ({
  href,
  children,
  onClick,
}: {
  href?: string;
  children: React.ReactNode;
  onClick?: () => void;
}) => {
  if (href) {
    return (
      <Link
        href={href}
        className="inline-block rounded-lg py-1 px-2 text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900"
      >
        {children}
      </Link>
    );
  }

  return (
    <button onClick={onClick} className="text-left block w-full p-2">
      {children}
    </button>
  );
};
