import { Fragment } from 'react';
import Link from 'next/link';
import { Popover, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { Button } from '@components/Button';
import { Container } from '@components/Container';
import { NavLink } from './NavLink';
import { Logo } from '@components/Logo';
import { signIn, signOut, useSession } from 'next-auth/react';
import { useRouter } from 'next/router';

function MobileNavLink({
  href,
  children,
  onClick,
}: {
  href?: string;
  children: React.ReactNode;
  onClick?: () => void;
}) {
  if (href) {
    return (
      <Popover.Button as={Link} href={href} className="block w-full p-2">
        {children}
      </Popover.Button>
    );
  }

  return (
    <Popover.Button onClick={onClick} className="block w-full p-2 text-left">
      {children}
    </Popover.Button>
  );
}

function MobileNavIcon({ open }: { open: boolean }) {
  return (
    <svg
      aria-hidden="true"
      className="h-3.5 w-3.5 overflow-visible stroke-slate-700"
      fill="none"
      strokeWidth={2}
      strokeLinecap="round"
    >
      <path
        d="M0 1H14M0 7H14M0 13H14"
        className={clsx(
          'origin-center transition',
          open && 'scale-90 opacity-0',
        )}
      />
      <path
        d="M2 2L12 12M12 2L2 12"
        className={clsx(
          'origin-center transition',
          !open && 'scale-90 opacity-0',
        )}
      />
    </svg>
  );
}

function MobileNavigation() {
  return (
    <Popover>
      <Popover.Button
        className="relative z-10 flex h-8 w-8 items-center justify-center [&:not(:focus-visible)]:focus:outline-none"
        aria-label="Toggle Navigation"
      >
        {({ open }: { open: boolean }) => <MobileNavIcon open={open} />}
      </Popover.Button>
      <Transition.Root>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="duration-150 ease-in"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Popover.Overlay className="fixed inset-0 bg-slate-300/50" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            as="div"
            className="absolute inset-x-0 top-full mt-4 flex origin-top flex-col rounded-2xl bg-white p-4 text-lg tracking-tight text-slate-900 shadow-xl ring-1 ring-slate-900/5"
          >
            <MobileNavLink
              href={
                typeof window !== 'undefined' &&
                window.location.pathname !== '/'
                  ? `/#features`
                  : '#features'
              }
            >
              Features
            </MobileNavLink>
            <MobileNavLink
              href={
                typeof window !== 'undefined' &&
                window.location.pathname !== '/'
                  ? `/#pricing`
                  : '#pricing'
              }
            >
              Pricing
            </MobileNavLink>
            <MobileNavLink
              href={
                typeof window !== 'undefined' &&
                window.location.pathname !== '/'
                  ? `/#faq`
                  : '#faq'
              }
            >
              FAQs
            </MobileNavLink>
          </Popover.Panel>
        </Transition.Child>
      </Transition.Root>
    </Popover>
  );
}

export function Header() {
  const { data: session } = useSession();
  const router = useRouter();
  const showLogout = session && router.pathname === '/account';

  return (
    <header className="py-10">
      <Container>
        <nav className="relative z-50 flex justify-between">
          <div className="flex items-center md:gap-x-12">
            <Link
              href="/"
              aria-label="Home"
              className="flex items-center gap-2 font-bold"
            >
              <Logo className="h-10 w-auto" />
              My Email Assistant
            </Link>
            <div className="hidden md:flex md:gap-x-6"></div>
          </div>
          <div className="flex items-center gap-x-5 md:gap-x-8">
            <div className="hidden md:block">
              <NavLink
                href={
                  typeof window !== 'undefined' &&
                  window.location.pathname !== '/'
                    ? `/#features`
                    : '#features'
                }
              >
                Features
              </NavLink>
              <NavLink
                href={
                  typeof window !== 'undefined' &&
                  window.location.pathname !== '/'
                    ? `/#pricing`
                    : '#pricing'
                }
              >
                Pricing
              </NavLink>
              <NavLink
                href={
                  typeof window !== 'undefined' &&
                  window.location.pathname !== '/'
                    ? `/#faq`
                    : '#faq'
                }
              >
                FAQs
              </NavLink>
            </div>
            <>
              {showLogout ? (
                <Button
                  onClick={() =>
                    signOut({ callbackUrl: `${window.location.origin}` })
                  }
                  color="blue"
                >
                  <span>Logout</span>
                </Button>
              ) : session ? (
                <Button
                  onClick={() => signIn(undefined, { callbackUrl: '/account' })}
                  color="blue"
                >
                  <span>Login</span>
                </Button>
              ) : (
                <Button
                  onClick={() => signIn(undefined, { callbackUrl: '/account' })}
                  color="blue"
                >
                  <span>Login</span>
                </Button>
              )}
            </>

            <div className="-mr-1 md:hidden">
              <MobileNavigation />
            </div>
          </div>
        </nav>
      </Container>
    </header>
  );
}
